@tailwind base;

@layer base {
  :root {
    --tw-color-primary: theme(colors.primary.DEFAULT);
    --tw-color-x-heading1: theme(colors.x-heading1);
    --tw-color-x-heading2: theme(colors.x-heading2);
    --tw-color-x-gray1: theme(colors.x-gray1);
    --tw-color-x-gray2: theme(colors.x-gray2);
    --tw-color-x-gray3: theme(colors.x-gray3);
    --tw-color-x-gray4: theme(colors.x-gray4);
    --tw-color-x-green: theme(colors.x-green);
    --tw-color-x-red: theme(colors.x-red);
    --tw-color-black: theme(colors.black);
    --tw-color-white: theme(colors.white);
  }

  body {
    @apply text-x-heading1 bg-black;
  }
  h1 {
    @apply text-xxl leading-[72px] my-[40px] font-thin text-white;
  }
  h2 {
    @apply text-xxl;
  }
  h3 {
    @apply text-lg lg:text-xxl font-extralight m-0;
  }
  h4 {
    @apply text-xl font-extralight mt-0 mb-5;
  }
  h5 {
    @apply text-lg font-semibold mt-0 mb-5;
  }
  hr {
    @apply my-5 text-white/20;
  }
  a {
    @apply text-white;
  }
  .scrollbar-tailwind {
    scrollbar-width: thin;
    scrollbar-color: theme("colors.scrollbarThumb")
      theme("colors.scrollbarTrack");
  }
  .scrollbar-tailwind::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scrollbar-tailwind::-webkit-scrollbar-thumb {
    background-color: theme("colors.scrollbarThumb");
    border-radius: 5px;
  }
  .scrollbar-tailwind::-webkit-scrollbar-track {
    background-color: theme("colors.scrollbarTrack");
    border-radius: 5px;
  }
}

@tailwind components;
@tailwind utilities;
